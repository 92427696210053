.btn {
    text-transform: uppercase;
    letter-spacing: 0.15rem;
    font-size: $font-size-sm;
}

.btn-lg {
    font-size: $font-size-base;
}

.btn-link {
    border-color: transparent;

    @each $color, $value in $theme-colors {
        &.btn-#{$color} {
            color: $value;
        }
    }

    &:hover,
    &:focus {
        text-decoration: none;
    }
}

.btn-white {
    color: $primary;

    &:hover,
    &:focus {
        color: $secondary;
    }
}

.btn-text-white {
    color: $white;

    &:hover,
    &:focus {
        color: $white;
    }
}

.btn-transparent {
    background-color: transparent;

    &:hover,
    &:focus {
        background-color: transparent;
    }
}