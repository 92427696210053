.icon-round {
    display: block;
    margin: 0 auto $spacer auto;
    width: 210px;
    height: 210px;
    text-align: center;
    vertical-align: middle;
    background-color: $white;
    border: 1px solid rgba($body-color, .05);
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: $gray-100;

    svg {
        filter: drop-shadow( 0  1px 0 $gray-200 );
    }
}

.icon-round-primary {
    color: $primary;

    svg {
        filter: drop-shadow( 0  1px 0 $secondary );
    }
}
