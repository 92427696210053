.header {
    position: relative;
    flex-grow: 0;
    background-color: transparent;
    padding: 0;
    min-height: $spacer * 3;
    color: $body-color;

    .btn-link {
        color: $body-color;
    }

    .btn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }

    .btn-left {
        left: 0;
    }

    .btn-right {
        right: 0;
    }
}

.header-light {
    //background-color: $light-computed;
    color: $light-computed;

    .btn-link {
        color: $light-computed;
    }
}

.header-dark {
    //background-color: $dark;
    color: $dark;

    .btn-link {
        color: $dark;
    }

    .header-title {
        font-weight: 300;
    }
}

.header-primary {
    //background-color: $primary;
    color: $primary;

    .btn-link {
        color: $primary;
    }

    .header-title {
        font-weight: 700;
    }
}

.header-title {
    font-size: 16px;
    margin: $spacer $spacer * 3;
    font-weight: $font-weight-normal;
    text-align: center;
    line-height: 1.5;
}

@include media-breakpoint-up( 'md' ) {
    .header {
        padding: $spacer * 0.5;
    }
}

@include media-breakpoint-up( 'lg' ) {
    .header {
        padding: $spacer;
    }
}

@include media-breakpoint-up( 'xl' ) {
    .header {
        padding: $spacer * 1.5;
    }
}