.contacts {
    @extend .transactions;
}

.contact {
    @extend .transaction;

    .public-key {
        color: $text-muted;
        font-size: $font-size-sm;
    }
}