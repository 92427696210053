.alert {
    text-align: center;
}

.alert-top {
    border-radius: 0;
    margin-bottom: 0;
}

.splash {
    .alert-danger {
        background-color: $white;
        color: $secondary;
    }
}
