.kpis {
    padding: 0;
    margin: 0 0 $spacer 0;
    list-style-type: none;
    background-color: $body-color;
    color: $white;
    border-radius: $border-radius-lg;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.kpi {
    position: relative;
    padding: $spacer $spacer $spacer * 1.5;
    text-align: center;
    flex-basis: 120px;
    flex-grow: 1;
}

.kpi-label {
    position: absolute;
    left: 0;
    right: 0;
    bottom: $spacer / 2;
    color: $text-muted;
    font-size: $font-size-xs;
    text-transform: uppercase;
}

.kpi-value {
    font-size: $h2-font-size;
    font-weight: 500;
}