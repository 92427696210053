.navbar {
    box-shadow: 0 0 1px rgba($dark, .15);

    .nav {
        width: 100%;
    }

    .nav-link {
        text-align: center;
        padding: $navbar-nav-link-padding-y $navbar-nav-link-padding-x;
        color: $body-color;

        &.active {
            color: $primary;
        }

        &.disabled {
            color: $text-muted;
        }

        .fas {
            color: $body-color;
            font-size: $navbar-icon-size;
        }

        .label {
            display: block;
            font-size: $navbar-label-size;
            text-transform: uppercase;
            color: $text-muted;
        }


    }
}