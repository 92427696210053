@mixin section-margin() {
    margin: $spacer 0;

    @include media-breakpoint-up( 'sm' ) {
        margin: $spacer * 1.5 0;
    }

    @include media-breakpoint-up( 'md' ) {
        margin: $spacer * 2 0;
    }

    @include media-breakpoint-up( 'lg' ) {
        margin: $spacer * 2.5 0;
    }

    @include media-breakpoint-up( 'xl' ) {
        margin: $spacer * 3 0;
    }
}

@mixin section-padding() {
    padding: $spacer 0;

    @include media-breakpoint-up( 'sm' ) {
        padding: $spacer * 1.5 0;
    }

    @include media-breakpoint-up( 'md' ) {
        padding: $spacer * 2 0;
    }

    @include media-breakpoint-up( 'lg' ) {
        padding: $spacer * 2.5 0;
    }

    @include media-breakpoint-up( 'xl' ) {
        padding: $spacer * 3 0;
    }
}
