.text-white p,
.ptext-white {
    font-weight: 300;

    strong {
        font-weight: 700;
    }
}

.font-xs {
    font-size: $font-size-xs;
}