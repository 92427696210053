.form-group {
    margin-bottom: $spacer * 2;
}
.form-actions {
    margin-bottom: $spacer;
}

.form-control {
    border-left: none;
    border-top: none;
    border-right: none;

    transition: border-color .25s ease;

    &:focus {
        color: $input-focus-color;
        background-color: $input-focus-bg;
        border-color: $input-focus-border-color;
        outline: 0;
        // Avoid using mixin so we can pass custom focus shadow properly
        box-shadow: none;
    }
}

.form-group-light .form-control {
    border-color: rgba($white, .25);
    color: $white;

    &:focus {
        border-color: $white;
    }

    &::placeholder {
        color: rgba($white, .5);
        // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
        opacity: 1;
    }
}

.custom-control-label {
    &:before {
        border: 1px solid $primary;
    }
}