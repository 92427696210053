@import "variables";
//@import "mixins";
@import "bootstrap";
@import "mixins";
@import "type";
@import "splash";
@import "navbar";
@import "buttons";
@import "header";
@import "forms";
@import "alert";
@import "hero";
@import "richtext";
@import "slider";
@import "onboarding";
@import "keys";
@import "private-key";
@import "icons";
@import "kpi";
@import "transactions";
@import "contacts";

body {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    background-color: $white;
    background-image: linear-gradient($white, $gray-100);

    padding-bottom: $navbar-height;
}

.container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
}