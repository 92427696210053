.transactions {
    padding: 0;
    margin: 0 0 $spacer 0;
    list-style-type: none;

    .transaction {
        display: block;
        position: relative;
        padding-top: $spacer / 2;
        padding-right: $spacer;
        padding-bottom: $spacer / 2;
        padding-left: $spacer * 2.5;
        border-bottom: 1px solid rgba($black, .15);
        text-decoration: none;
        color: $body-color;

        &:hover,
        &:focus {
            text-decoration: none;
            color: $body-color;

            svg {
                color: $gray-400;
            }
        }

        .sender {
            position: absolute;
            left: 0;
            top: $spacer / 2;
            width: $spacer * 2;
            height: $spacer * 2;
            background-color: $primary;
            text-align: center;
            vertical-align: middle;
            line-height: $spacer * 2;
            text-transform: uppercase;
            color: $white;
            border-radius: $border-radius;
            font-size: $font-size-sm;
        }

        .public-key,
        .timestamp,
        .name {
            display: block;
            font-size: $font-size-xs;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .timestamp {
            color: $text-muted;
        }

        .amount {
            display: block;
            //text-align: right;
        }

        .arrow {
            position: absolute;
            right: 0;
            top: $spacer;
            color: $gray-200;
        }

        @media (min-width: 350px) {
            padding-top: $spacer;
            padding-bottom: $spacer;
            
            .sender {
                top: $spacer;
            }

            .timestamp,
            .public-key {
                width: 55%;
            }
            .amount {
                width: 40%;
                position: absolute;
                right: $spacer;
                top: $spacer;
                text-align: right;
            }
            .arrow {
                top: $spacer * 1.2;
            }
        }
    }
}

.transaction-negative {
    .sender {
        background: $secondary;
    }

    .amount {
        color: $text-muted;
    }
}