.hero {
    display: flex;
    flex-direction: column;
    align-content: space-between;
    position: relative;
    min-height: 50vh;
    background-color: $dark;
    color: $white;
}

.hero-img-wrapper {
    opacity: .5;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: $dark;
    background-blend-mode: multiply;
    background-size: cover;
    background-position: center center;
}
