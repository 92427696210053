.key {
    font-family: 'Courier New', Courier, monospace;
    word-wrap: break-word;
}

.key-xs {
    font-size: $font-size-xs;
}

.key-sm {
    font-size: $font-size-sm;
}

.key-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: normal;
}

.key-output {
    position: relative;
    display: block;
    background-color: $white;
    border: 1px solid $input-border-color;
    padding: $spacer $spacer $spacer * 2;
    margin-bottom: $spacer;
    border-radius: $border-radius-lg;

    .instructions {
        position: absolute;
        left: 0;
        right: 0;
        bottom: $spacer / 2;
        margin-bottom: 0;
    }
}

.key-private {
    text-align: center;

    .key {
        letter-spacing: 4px;
    }
}

.key-in-words {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    //padding: 0 0 0 $spacer * 3;
    font-family: 'Courier New', Courier, monospace;
    margin-left: -10px;
    //font-size: $font-size-sm;


    > li {
        position: relative;
        list-style-type: none;
        padding-left: 30px;
        width: 100%;

        @media (min-width: 300px) {
            width: 50%;
        }
        @media (min-width: 350px) {
            width: 33.333333%;
        }

        .key-in-words-number {
            position: absolute;
            left: 0;
            bottom: 3px;
        }
    }
}

.key-in-words-number {
    display: inline-block;
    width: 20px;
    text-align: right;
    font-size: $font-size-xs;
    font-family: $font-family-sans-serif;
    color: $text-muted;
}